<template>
  <span>
    <div v-if="top">
      <h4>Scoping questions editor</h4>
    </div>
    <b-card class="border-dark" v-if="questions.length > 0 && !busy">
      <b-form-group>
        Questions in group: {{response.questions.length}}
        <div>
          <div v-if="questions.length > 1">
            <b-form-radio class="mt-4" v-model="condition" value="and">AND - all of the questions must be true</b-form-radio>
            <b-form-radio v-model="condition" value="or">OR - one of the questions must be true</b-form-radio>
          </div>
          <div v-for="(question, index) in questions" :key="index + '-question'">
            <b-form-input class="mt-4" v-model="questions[index].question" placeholder="Provide question"></b-form-input>
            <b-button class="mr-4" variant="outline-danger" size="sm" @click="questionDelete(index)">delete</b-button>
            <scoping-form v-model="questions[index].group" :top="false" />
          </div>
        </div>
      </b-form-group>
    </b-card>
    <b-button v-if="questions.length === 0" size="sm" variant="outline-primary" @click="questionAdd">add question group</b-button>
    <b-button v-if="questions.length > 0" size="sm" variant="outline-primary" @click="questionAdd">add question</b-button>
    <b-button class="ml-4" v-if="top" size="sm" variant="outline-primary" @click="save">save and close</b-button>
  </span>
</template>

<script>
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  name: 'ScopingForm',
  props: ['id', 'top', 'value'],
  components: {
  },
  computed: {
    response: function () {
      const group = {
        condition: this.condition,
        questions: this.questions
      }
      this.$emit('input', group)
      if (this.top) {
        console.log(group)
      }
      return group
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    if (this.value) {
      this.condition = this.value.condition
      this.questions = this.value.questions
    }
  },
  data () {
    return {
      busy: false,
      condition: 'and',
      edit: false,
      questions: []
    }
  },
  methods: {
    save: async function () {
      this.busy = true
      try {
        const params = {
          body: {
            scoping: this.response
          }
        }
        if (params.body.scoping.questions.length === 0) {
          params.body.scoping = null
        }
        const response = await this.$Amplify.API.put('cosmos', `/standard/impactinitiative/${this.id}`, params)
        this.$logger.debug('saved OK:', response)
        this.$emit('save', this.response)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
        this.busy = false
      }
    },
    questionAdd: async function () {
      this.busy = true
      await delay(50)
      this.edit = true
      this.questions.push({
        answer: 'undefined',
        childrenAssessment: true,
        question: 'Provide question?',
        group: {
          condition: 'and', questions: []
        },
        uuid: uuidv4()
      })
      this.busy = false
    },
    questionDelete: async function (i) {
      this.busy = true
      await delay(50)
      console.log('to remove: ' + i)
      console.log('removing')
      const questions = this.questions
      _.remove(questions, function (value, index) {
        console.log(index, i)
        return index === i
      })
      this.questions = []
      this.questions = questions
      this.busy = false
    }
  }
}
</script>

<style scoped>

</style>
