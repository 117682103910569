<template>
  <div v-if="value.questions.length > 0">
    <div v-if="top">
      <h4>Scoping questions</h4>
    </div>
    <div v-if="value.questions.length > 1"><strong>{{value.condition}}</strong></div>
    <div v-for="(line, index) in value.questions" :key="index + '-questions'" class="pl-2" style="border-left: 2px solid black">
        {{line.question}}
        <div class="ml-4">
          <scoping-questions :top="false" v-model="line.group" />
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScopingQuestions',
  props: ['top', 'value'],
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
  },
  data () {
    return {
      busy: false,
      condition: 'and',
      edit: false,
      questions: []
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
