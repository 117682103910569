<template>
  <div v-if="questions.length > 0 && ready">
    <div v-if="top">
      <h4>Quick scope assessment</h4>
      <b-alert v-if="outcome && top" show variant="success">in scope</b-alert>
      <b-alert v-if="!outcome && top" show variant="danger">not in scope</b-alert>
    </div>
    <div class="pt-4"><strong v-if="questions.length > 1">{{condition}}</strong> - <strong>{{outcome}}</strong></div>
    <div v-for="line in questions" :key="line.uuid" id="line.uuid" class="pl-2" style="border-left: 2px solid black">
        <div class="pt-3">{{line.question}} - {{line.answer}}</div>
        <b-form-radio-group v-model="line.answer" :options="options" />
        <div class="ml-4" v-if="line.group.questions.length > 0">
          <scoping-single-assessment :top="false" :level="level + 1" :input="line.group" v-model="line.childrenAssessment" />
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScopingSingleAssessment',
  props: ['top', 'input', 'level', 'value'],
  components: {
  },
  computed: {
    outcome: function () {
      let result = false
      const questions = this.questions
      if (questions.length > 0) {
        if (this.condition === 'or') {
          questions.forEach(x => {
            if (x.answer === 'yes' && x.childrenAssessment === true) {
              result = true
            }
          })
        }
        if (this.condition === 'and') {
          result = true
          questions.forEach(x => {
            if (x.answer !== 'yes') {
              result = false
            }
            if (x.childrenAssessment !== true) {
              result = false
            }
          })
        }
      } else {
        result = true
      }
      this.$emit('input', result)
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.condition = this.input.condition
    this.questions = this.input.questions
    this.ready = true
  },
  data () {
    return {
      condition: '',
      options: [
        { text: 'undefined', value: 'undefined' },
        { text: 'no', value: 'no' },
        { text: 'yes', value: 'yes' }
      ],
      questions: [],
      ready: false
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
