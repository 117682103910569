<template>
  <div>
    <div v-for="group in rowsShow" :key="'group' + group.id">
      <h4 class="mt-5">{{ group.name }}</h4>
      <div style="font-size: 1.2em" v-for="child in group.children" :key="'child'+child.id" >
        {{ child.name }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'InitiativeTaxonomy',
  props: ['rows'],
  computed: {
    rowsShow: function () {
      let results = []
      let parents = []
      _.each(this.rows, row => {
        parents = parents.concat(row.parents)
      })
      results = _.uniqBy(parents, 'id')
      _.each(results, parent => {
        parent.children = []
        _.each(this.rows, row => {
          if (row.parents.find(e => e.id === parent.id)) {
            parent.children.push({ name: row.name, id: row.id })
          }
        })
      })
      return results
    }
  }
}
</script>
